import React, { Fragment } from 'react';
import { Table, Form, Modal, Button, Card, Row, Col, Alert } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import DebounceInput from 'react-debounce-input';
import { Copy, Edit2, Plus, PlusCircle, Trash2, X, ZoomIn } from "react-feather";
import LowerElementsComponent from '../../components/component/lower-elements';
import SelectSync from '../../components/select/sync';
import Icon from '../../components/icon';
import getProto from '../../proto';
import TextEditor from '../../components/text-editor';

class OfferBasic extends LowerElementsComponent {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            selectedSubSectionUuid: null
        }
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    render() {

        const {
            selectedSubSectionUuid
        } = this.state;

        const marginTypesDisabled = []; // !!HARDCODED!!

        const {
            isLoading,
            closed,
            offerElements,
            costTotal,
            costTotalGross,
            marginType,
            marginTypes,
            // marginTypesDisabled,
            vat,
            vatStakes,
            pvPowers,
            pvPower
        } = this.props;

        let selectedSubSectionForPopup = null;
        offerElements.forEach(
            (mainSection) => {
                if (mainSection.children) {
                    mainSection.children.forEach(
                        element => {
                            if (element.uuid === selectedSubSectionUuid) {
                                selectedSubSectionForPopup = { ...element };
                            }
                        }
                    )
                }
            }
        );

        const marginTypesPrepared = marginTypes.filter(margin => !marginTypesDisabled.includes(margin)).map(margin => {
            let theLabel = <span>{this.props.t(`page.calculation.offer.basic.margin.${margin}`)}</span>;
            return {
                label: <>{theLabel}</>,
                value: margin
            }
        });

        const vatStakesPrepared = vatStakes.map(vat => {
            let theLabel = `${vat} %`;
            return {
                label: theLabel,
                value: vat
            }
        });

        const getWithMargin = (costPerUnit, marginPercent) => {
            const $defaultMarginPercent = (100 - this.parseFloatFixed(marginPercent)) / 100;
            const costPerUnitFixed = this.parseFloatFixed(costPerUnit);
            if ($defaultMarginPercent > 0) {
                return ((costPerUnitFixed / $defaultMarginPercent) - costPerUnitFixed) + costPerUnitFixed;
            }
            return 0;
        };

        const getMarginValue = (costPerUnit, nettoCost) => {
            return nettoCost - costPerUnit;
        };

        return (
            <Fragment>

                <ReactTooltip />

                <Modal
                    show={this.state.modal}
                    onHide={() => { this.setState({ selectedSubSectionUuid: null, modal: false }) }}
                    style={{ maxWidth: "100%" }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                selectedSubSectionForPopup?.label || this.props.t('page.calculation.offer.basic.modal.title')
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-0 pt-0">
                        {
                            selectedSubSectionForPopup?.allowCustom ? (
                                <Form>

                                    <Form.Row className='px-25 py-10'>
                                        <Form.Group as={Col} sm={12} md={12} className="required">
                                            <Form.Label>
                                                {this.props.t('page.calculation.offer.basic.modal.categoryLabel')}
                                            </Form.Label>
                                            <Form.Control
                                                required={true}
                                                type="text"
                                                value={selectedSubSectionForPopup.label}
                                                onChange={
                                                    (evt) => {
                                                        this.handleElementChange2(
                                                            'offerElements',
                                                            selectedSubSectionForPopup.uuid,
                                                            {
                                                                'label': this.val(evt),
                                                            }
                                                        );
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12} md={12}>
                                            <Form.Label>
                                                {this.props.t('page.calculation.offer.basic.modal.categoryDescription')}
                                            </Form.Label>
                                            <TextEditor
                                                api={this.props.api}
                                                mentions={false}
                                                editorId="custom-item-editor"
                                                value={selectedSubSectionForPopup.descriptionCustom || ''}
                                                simpleToolbar={true}
                                                config={{ autoGrow_maxHeight: 150 }}
                                                onChange={
                                                    (html) => {
                                                        this.handleElementChange2(
                                                            'offerElements',
                                                            selectedSubSectionForPopup.uuid,
                                                            {
                                                                'descriptionCustom': html,
                                                            }
                                                        );
                                                    }
                                                }
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <Table size="xs" className="mt-0 mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th scope="col" className="pl-30" style={{ minWidth: 130 }}>
                                                    {this.props.t('page.calculation.offer.basic.table.bcId')}
                                                </th>
                                                <th scope="col" className="pl-30" style={{ minWidth: 200 }}>
                                                    {this.props.t('page.calculation.offer.basic.table.product')}
                                                </th>
                                                <th scope="col" style={{ minWidth: 60 }}>
                                                    {this.props.t('page.calculation.offer.basic.table.amount')}
                                                </th>
                                                <th scope="col" style={{ minWidth: 60 }}>
                                                    {this.props.t('page.calculation.offer.basic.table.unit')}
                                                </th>
                                                <th scope="col" style={{ minWidth: 60 }}>
                                                    {this.props.t('page.calculation.offer.basic.table.costPerUnit')}
                                                </th>
                                                <th scope="col" style={{ minWidth: 60 }} className="pr-20">
                                                    {this.props.t('page.calculation.offer.basic.table.margin')}
                                                </th>
                                                <th scope="col" style={{ width: 100 }} className="pr-20 text-right">
                                                    {this.props.t('page.calculation.offer.basic.table.value')}
                                                </th>
                                                <th scope="col" style={{ width: 100 }} className="pr-20 text-right">
                                                    {this.props.t('page.calculation.offer.basic.table.gross')}
                                                </th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                selectedSubSectionForPopup.children.map(
                                                    (subComponent) => {
                                                        return (
                                                            <tr key={subComponent.uuid}>
                                                                <td className="align-bottom pl-30">
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        placeholder="..."
                                                                        value={subComponent.bcId}
                                                                        onChange={
                                                                            (evt) => {
                                                                                this.handleElementChange(
                                                                                    'offerElements',
                                                                                    subComponent.uuid,
                                                                                    'bcId',
                                                                                    this.val(evt)
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="align-bottom pl-30">
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        placeholder="..."
                                                                        value={subComponent.label}
                                                                        onChange={
                                                                            (evt) => {
                                                                                this.handleElementChange(
                                                                                    'offerElements',
                                                                                    subComponent.uuid,
                                                                                    'label',
                                                                                    this.val(evt)
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="align-bottom">
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="number"
                                                                        placeholder="..."
                                                                        value={subComponent.amount}
                                                                        readOnly={subComponent.amountAuto}
                                                                        title={subComponent.amountAuto}
                                                                        onChange={
                                                                            (evt) => {
                                                                                this.handleElementChange(
                                                                                    'offerElements',
                                                                                    subComponent.uuid,
                                                                                    'amount',
                                                                                    this.val(evt)
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="align-bottom">
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="text"
                                                                        placeholder="..."
                                                                        value={subComponent.unit}
                                                                        onChange={
                                                                            (evt) => {
                                                                                this.handleElementChange(
                                                                                    'offerElements',
                                                                                    subComponent.uuid,
                                                                                    'unit',
                                                                                    this.val(evt)
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="align-bottom">
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.01"
                                                                        placeholder="..."
                                                                        value={subComponent.costPerUnit}
                                                                        onChange={
                                                                            (evt) => {
                                                                                this.handleElementChange2(
                                                                                    'offerElements',
                                                                                    subComponent.uuid,
                                                                                    {
                                                                                        'costPerUnit': this.valFloatPositive(evt),
                                                                                        'costPerUnitWithMargin': {
                                                                                            'zero': this.valFloatPositive(evt),
                                                                                            'min': getWithMargin(this.valFloatPositive(evt), subComponent.defaultMargin),
                                                                                            'default': getWithMargin(this.valFloatPositive(evt), subComponent.defaultMargin),
                                                                                            'max': getWithMargin(this.valFloatPositive(evt), subComponent.defaultMargin)
                                                                                        },
                                                                                        'defaultMargin': subComponent.defaultMargin,
                                                                                        'minMargin': subComponent.defaultMargin,
                                                                                        'maxMargin': subComponent.defaultMargin,
                                                                                        'margin': {
                                                                                            'zero': 0,
                                                                                            'min': getMarginValue(this.valFloatPositive(evt), getWithMargin(this.valFloatPositive(evt), subComponent.defaultMargin)),
                                                                                            'default': getMarginValue(this.valFloatPositive(evt), getWithMargin(this.valFloatPositive(evt), subComponent.defaultMargin)),
                                                                                            'max': getMarginValue(this.valFloatPositive(evt), getWithMargin(this.valFloatPositive(evt), subComponent.defaultMargin))
                                                                                        }
                                                                                    }
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="align-bottom">
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.1"
                                                                        placeholder="..."
                                                                        value={subComponent.defaultMargin}
                                                                        onChange={
                                                                            (evt) => {
                                                                                this.handleElementChange2(
                                                                                    'offerElements',
                                                                                    subComponent.uuid,
                                                                                    {
                                                                                        'defaultMargin': this.valFloatPositive(evt),
                                                                                        'minMargin': this.valFloatPositive(evt),
                                                                                        'maxMargin': this.valFloatPositive(evt),
                                                                                        'margin': {
                                                                                            'zero': 0,
                                                                                            'min': getMarginValue(subComponent.costPerUnit, getWithMargin(subComponent.costPerUnit, this.valFloatPositive(evt))),
                                                                                            'default': getMarginValue(subComponent.costPerUnit, getWithMargin(subComponent.costPerUnit, this.valFloatPositive(evt))),
                                                                                            'max': getMarginValue(subComponent.costPerUnit, getWithMargin(subComponent.costPerUnit, this.valFloatPositive(evt)))
                                                                                        },
                                                                                        'costPerUnitWithMargin': {
                                                                                            'zero': subComponent.costPerUnit,
                                                                                            'min': getWithMargin(subComponent.costPerUnit, this.valFloatPositive(evt)),
                                                                                            'default': getWithMargin(subComponent.costPerUnit, this.valFloatPositive(evt)),
                                                                                            'max': getWithMargin(subComponent.costPerUnit, this.valFloatPositive(evt))
                                                                                        }
                                                                                    }
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="align-bottom pr-20 text-right">
                                                                    {this.parseFloatFixed(subComponent.costTotal).toFixed(2)}
                                                                </td>
                                                                <td className="align-bottom pr-20 text-right">
                                                                    {this.parseFloatFixed(subComponent.costTotalGross).toFixed(2)}
                                                                </td>
                                                                <td className="align-bottom pr-20 text-right">
                                                                    <Button className='p-0 fs-12' variant="btn" onClick={() => {
                                                                        this.props.setUpperState({ offerElements: this.handleElementTrash2('offerElements', subComponent.uuid) })
                                                                    }}>
                                                                        <X size={14} />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={9} className="align-bottom px-20">
                                                    <button
                                                        type="button"
                                                        className={"btn pr-0 pl-10"}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.setUpperState({
                                                                offerElements: [...offerElements].map(itm => {
                                                                    let newItm = { ...itm };
                                                                    newItm.children = newItm.children.map(itmChild => {
                                                                        if (itmChild.uuid !== selectedSubSectionUuid) {
                                                                            return itmChild;
                                                                        }
                                                                        let newItmChild = { ...itmChild };
                                                                        newItmChild.children.push(
                                                                            getProto('product',
                                                                                {
                                                                                    "parent": selectedSubSectionForPopup.uuid,
                                                                                    "isCustom": true,
                                                                                    "margin": {
                                                                                        "zero": 0,
                                                                                        "default": 0,
                                                                                        "min": 0,
                                                                                        "max": 0
                                                                                    },
                                                                                    "costPerUnitWithMargin": {
                                                                                        "zero": 0,
                                                                                        "min": 0,
                                                                                        "default": 0,
                                                                                        "max": 0
                                                                                    }
                                                                                }
                                                                            )
                                                                        )
                                                                        return newItmChild;
                                                                    });
                                                                    return newItm;
                                                                })
                                                            })
                                                        }}
                                                    >
                                                        <Plus size={16} />
                                                        {this.props.t('common.button.add')}
                                                    </button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Form>
                            ) : (
                                <Table size="xs" className="mt-0 mb-0" responsive>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pl-30">
                                                {this.props.t('page.calculation.offer.basic.table.product')}
                                            </th>
                                            <th scope="col" style={{ width: 150 }}>
                                                {this.props.t('page.calculation.offer.basic.table.amount')}
                                            </th>
                                            <th scope="col" style={{ width: 80 }}>
                                                {this.props.t('page.calculation.offer.basic.table.unit')}
                                            </th>
                                            <th scope="col" style={{ width: 130 }} className="pr-20 text-right">
                                                {this.props.t('page.calculation.offer.basic.table.value')}
                                            </th>
                                            <th scope="col" style={{ width: 130 }} className="pr-20 text-right">
                                                {this.props.t('page.calculation.offer.basic.table.gross')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedSubSectionForPopup?.children.map(
                                                (subComponent) => {
                                                    return (
                                                        <tr key={subComponent.uuid}>
                                                            <td className="align-bottom pl-30">
                                                                {subComponent.label}
                                                            </td>
                                                            <td className="align-bottom">
                                                                <Form.Control
                                                                    size="sm"
                                                                    type="number"
                                                                    placeholder="..."
                                                                    value={subComponent.amount}
                                                                    readOnly={subComponent.amountAuto}
                                                                    title={subComponent.amountAuto}
                                                                    onChange={
                                                                        (evt) => {
                                                                            this.handleElementChange(
                                                                                'offerElements',
                                                                                subComponent.uuid,
                                                                                'amount',
                                                                                this.val(evt)
                                                                            )
                                                                        }
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="align-bottom">
                                                                {subComponent.unit}
                                                            </td>
                                                            <td className="align-bottom pr-20 text-right">
                                                                {this.parseFloatFixed(subComponent.costTotal).toFixed(2)}
                                                            </td>
                                                            <td className="align-bottom pr-20 text-right">
                                                                {this.parseFloatFixed(subComponent.costTotalGross).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                        }
                                    </tbody>
                                </Table>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer className="px-20">
                        <Button variant="success" onClick={() => { this.setState({ selectedSubSectionUuid: null, modal: false }) }}>
                            {this.props.t('page.calculation.offer.basic.modal.done')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Card className="mt-5 mb-30 rui-page-sidebar-sticky" style={{ background: '#fff', zIndex: 99 }}>
                    <Card.Body className="pb-5">
                        <Row>
                            {
                                this.props.hasPermission(['VIEW_OFFER_MARGIN']).any ?
                                    (
                                        <Col sm={12} md={6}>
                                            <Form.Group>
                                                <Form.Row>
                                                    <Form.Label column="md" sm={12} md={4}>
                                                        {this.props.t('page.calculation.offer.basic.margin.select')}
                                                    </Form.Label>
                                                    <Col sm={12} md={8}>
                                                        <SelectSync
                                                            t={this.props.t}
                                                            api={this.props.api}
                                                            showToast={this.props.showToast}
                                                            placeholder={this.props.t(`common.placeholder.any`)}
                                                            disabled={isLoading || closed}
                                                            cache={true}
                                                            preload={true}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            options={marginTypesPrepared}
                                                            value={marginTypesPrepared.find(itm => itm.value === marginType)}
                                                            onChange={e => this.setUpperState({ marginType: e?.value })}
                                                        />
                                                    </Col>
                                                </Form.Row>
                                            </Form.Group>
                                        </Col>
                                    )
                                    :
                                    (
                                        <></>
                                    )
                            }
                            <Col sm={12} md={this.props.hasPermission(['VIEW_OFFER_MARGIN']).any ? 6 : 12}>
                                <Form.Group>
                                    <Form.Row>
                                        <Form.Label column="md" sm={12} md={4}>
                                            {this.props.t('page.calculation.offer.basic.vat.select')}
                                        </Form.Label>
                                        <Col sm={12} md={8}>
                                            <SelectSync
                                                t={this.props.t}
                                                api={this.props.api}
                                                showToast={this.props.showToast}
                                                placeholder={this.props.t(`common.placeholder.any`)}
                                                disabled={isLoading || closed}
                                                cache={true}
                                                preload={true}
                                                isClearable={false}
                                                isSearchable={false}
                                                options={vatStakesPrepared}
                                                value={vatStakesPrepared.find(itm => itm.value === vat)}
                                                onChange={e => this.setUpperState({ vat: this.parseFloatFixed(e?.value) })}
                                            />
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                {
                    pvPowers && pvPowers.length > 1 && (
                        <Alert variant="info" className="mt-5 mb-30 ">{this.props.t('common.toast.adjustPvPowerToChangeProduct')}</Alert>
                    )
                }

                <Card style={{ borderTop: 0 }}>
                    <Card.Body className="p-0">
                        <Table size="xs" className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ minWidth: 200 }} className="pl-20">
                                        {this.props.t('page.calculation.offer.basic.table.offer')}
                                        {` / `}
                                        {this.props.t('page.calculation.offer.basic.table.product')}
                                    </th>
                                    <th scope="col" style={{ minWidth: 100 }} className="pl-10">
                                        {this.props.t('page.calculation.offer.basic.table.amount')}
                                    </th>
                                    <th scope="col" style={{ minWidth: 80 }} className="pl-10">
                                        {this.props.t('page.calculation.offer.basic.table.unit')}
                                    </th>
                                    {
                                        this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any && (
                                            <th scope="col" style={{ minWidth: 120 }} className="pl-10" >
                                                {this.props.t('page.calculation.offer.basic.table.discount')}
                                            </th>
                                        )
                                    }
                                    <th scope="col" style={{ minWidth: 80 }} className="text-right" >
                                        {this.props.t('page.calculation.offer.basic.table.value')}
                                    </th>
                                    <th scope="col" style={{ minWidth: 80 }} className="pr-20 text-right">
                                        {this.props.t('page.calculation.offer.basic.table.gross')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    offerElements.map((mainSection) => {
                                        let {
                                            selectedSubSection
                                        } = mainSection
                                        return (
                                            <tr key={mainSection.uuid} id={mainSection.uuid}>
                                                <td className="align-bottom pl-20">
                                                    <p className="my-5">
                                                        {mainSection.label}
                                                        {
                                                            this.props.hasPermission(['VIEW_OFFER_PRODUCT_DETAILS_BUTTON']).any && (
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-uniform ${!selectedSubSection || isLoading || closed ? 'text-default' : 'text-info'} p-0`}
                                                                    style={{ marginTop: -5, marginLeft: 5 }}
                                                                    disabled={!selectedSubSection || isLoading || closed}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            selectedSubSectionUuid: selectedSubSection.uuid,
                                                                            modal: true
                                                                        })
                                                                    }}
                                                                >
                                                                    {
                                                                        selectedSubSection && selectedSubSection.allowCustom ? (
                                                                            <i data-tip={this.props.t('common.tooltip.edit')} className="text-white bg-success p-2">
                                                                                <Edit2 size={14} />
                                                                            </i>
                                                                        ) : (
                                                                            <i data-tip={this.props.t('page.calculation.offer.basic.table.previewValues')}>
                                                                                <ZoomIn size={14} />
                                                                            </i>
                                                                        )
                                                                    }
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            mainSection.allowCopy && (
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-uniform ${isLoading || closed ? 'text-default' : 'text-info'} p-0`}
                                                                    style={{ marginTop: -5, marginLeft: 5 }}
                                                                    disabled={isLoading || closed}
                                                                    onClick={(e) => {
                                                                        this.props.treeNodeCopy(mainSection.uuid)
                                                                    }}
                                                                >
                                                                    <i data-tip={this.props.t('common.tooltip.copy')}>
                                                                        <Copy size={14} />
                                                                    </i>
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            mainSection.isCopy && (
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-uniform ${isLoading || closed ? 'text-default' : 'text-danger'} p-0`}
                                                                    style={{ marginTop: -5, marginLeft: 5 }}
                                                                    disabled={isLoading || closed}
                                                                    onClick={(e) => {
                                                                        this.props.treeNodeRemove(mainSection.uuid)
                                                                    }}
                                                                >
                                                                    <i data-tip={this.props.t('common.tooltip.remove')}>
                                                                        <Trash2 size={14} />
                                                                    </i>
                                                                </button>
                                                            )
                                                        }
                                                    </p>
                                                    <Form.Control
                                                        size="lg"
                                                        as="select"
                                                        custom
                                                        value={mainSection.value || ""}
                                                        disabled={isLoading || closed}
                                                        onChange={
                                                            (evt) => {
                                                                this.handleElementChange(
                                                                    'offerElements',
                                                                    mainSection.uuid,
                                                                    'value',
                                                                    this.val(evt)
                                                                )
                                                            }
                                                        }
                                                    >
                                                        {
                                                            mainSection.required ?
                                                                (
                                                                    <></>
                                                                )
                                                                :
                                                                (
                                                                    <option value={""}></option>
                                                                )
                                                        }
                                                        {
                                                            mainSection.children.map((elementGroup) => {
                                                                return (
                                                                    <option
                                                                        key={elementGroup.uuid}
                                                                        value={elementGroup.uuid}
                                                                        disabled={elementGroup.pvPowerSetter && elementGroup.pvPower.toString() !== pvPower.toString() ? true : false}
                                                                    >
                                                                        {elementGroup.label}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Control>
                                                </td>
                                                {
                                                    selectedSubSection
                                                        ?
                                                        (
                                                            <>
                                                                <td className="align-bottom">
                                                                    <label className="d-none">
                                                                        {selectedSubSection.label}
                                                                    </label>
                                                                    <DebounceInput
                                                                        className={selectedSubSection.selectedSubSectionNeedsInput ? "form-control form-control-sm bg-warning" : "form-control form-control-sm"}
                                                                        minLength={0}
                                                                        debounceTimeout={250}
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.01"
                                                                        placeholder="..."
                                                                        value={selectedSubSection.amount}
                                                                        readOnly={selectedSubSection.amountAuto}
                                                                        title={selectedSubSection.amountAuto}
                                                                        required={selectedSubSection.selectedSubSectionNeedsInput}
                                                                        disabled={isLoading || closed}
                                                                        onChange={
                                                                            (evt) => {
                                                                                this.handleElementChange(
                                                                                    'offerElements',
                                                                                    selectedSubSection.uuid,
                                                                                    'amount',
                                                                                    this.val(evt)
                                                                                )
                                                                            }
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="align-bottom">
                                                                    {selectedSubSection.unit}
                                                                </td>
                                                                {
                                                                    this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any && (
                                                                        this.props.isRegion(['SWEDEN']) ? (
                                                                            <td className="align-bottom">
                                                                                <DebounceInput
                                                                                    className="form-control form-control-sm"
                                                                                    minLength={0}
                                                                                    debounceTimeout={250}
                                                                                    type="number"
                                                                                    placeholder="0.00"
                                                                                    disabled={isLoading || !this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any}
                                                                                    min="0"
                                                                                    value={selectedSubSection.discount || ""}
                                                                                    step="0.01"
                                                                                    onChange={
                                                                                        (evt) => {
                                                                                            let value = this.parseFloatFixed(this.val(evt));
                                                                                            this.handleElementChange(
                                                                                                'offerElements',
                                                                                                selectedSubSection.uuid,
                                                                                                'discount',
                                                                                                value
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        ) : (
                                                                            <td className="align-bottom">
                                                                                <DebounceInput
                                                                                    className="form-control form-control-sm"
                                                                                    minLength={0}
                                                                                    debounceTimeout={250}
                                                                                    type="number"
                                                                                    placeholder="0.00"
                                                                                    disabled={!selectedSubSection.maxDiscount || isLoading || !this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any}
                                                                                    min="0"
                                                                                    max={selectedSubSection.maxDiscount || 0}
                                                                                    value={selectedSubSection.discount || ""}
                                                                                    step="0.01"
                                                                                    data-tip={`Max: ${selectedSubSection.maxDiscount || 0}`}
                                                                                    onChange={
                                                                                        (evt) => {
                                                                                            let value = this.parseFloatFixed(this.val(evt));
                                                                                            this.handleElementChange(
                                                                                                'offerElements',
                                                                                                selectedSubSection.uuid,
                                                                                                'discount',
                                                                                                value
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        )
                                                                    )
                                                                }
                                                                <td className="align-bottom text-right">
                                                                    {selectedSubSection ? this.parseFloatFixed(selectedSubSection.costTotal).toFixed(2) : '---'}
                                                                </td>
                                                                <td className="align-bottom pr-20 text-right">
                                                                    {selectedSubSection ? this.parseFloatFixed(selectedSubSection.costTotalGross).toFixed(2) : '---'}
                                                                </td>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <td className="align-bottom">
                                                                    ---
                                                                </td>
                                                                <td className="align-bottom">
                                                                    ---
                                                                </td>
                                                                {
                                                                    this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any && (
                                                                        <td className="align-bottom">
                                                                            ---
                                                                        </td>
                                                                    )
                                                                }
                                                                <td className="align-bottom pr-20 text-right">
                                                                    ---
                                                                </td>
                                                                <td className="align-bottom pr-20 text-right">
                                                                    ---
                                                                </td>
                                                            </>
                                                        )
                                                }
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={this.props.hasPermission(['VIEW_OFFER_DISCOUNT']).any ? 4 : 3} className="align-bottom pl-20 text-right">
                                        <strong>
                                            {this.props.t('page.calculation.offer.basic.table.sum')}
                                        </strong>
                                    </td>
                                    <td className="align-bottom pr-20 text-right">
                                        <strong>{costTotal ? costTotal.toFixed(2) : '---'}</strong>
                                    </td>
                                    <td className="align-bottom pr-20 text-right">
                                        <strong>{costTotalGross ? costTotalGross.toFixed(2) : '---'}</strong>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Card.Body>
                </Card>
            </Fragment >

        );
    }
}
export default OfferBasic;