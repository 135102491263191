import './style.scss';
import React, { Fragment } from 'react';
import {FormGroup, Form, Row, Col, Modal, Card, Table, Spinner, Button} from 'react-bootstrap';
import BaseComponent from '../../components/component/base';
import Icon from '../../components/icon';
import ReactTooltip from "react-tooltip";
import Datatable from "../../components/datatable";

class Content extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            variables: {},
            loading: false,
            downloading: false,
            saving: false,
            lastChange: null,
            modalSync: false,
            modalSyncLoading: false,
            syncPreview: [],
            selectedPath: [],
            definedVariables: {},
            productTypes: [],
            syncAll: false,
            syncItems: [],
            modal: false,
            selectedProduct: null,
            newProduct: false
        };
    }

    componentDidMount() {
        this.fetchConfig();
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild();
    }

    fetchConfig(callback) {
        this.props.api.post(
            `/project/data`,
            {
                "file": {
                    "names": ["product_types"]
                }
            }, (data) => {
                this.setState({ productTypes: (data.product_types || []) });
            }
        );
    }

    maybeSave(e) {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            downloading: true,
            saving: true
        }, () => {
            const isNewProduct = this.state.selectedProduct.uuid_0 === null;
            const request = {
                'label': this.state.selectedProduct.label_1,
                'bcId': this.state.selectedProduct.bcId_2,
                'type': this.state.selectedProduct.type_3,
                'unit': this.state.selectedProduct.unit_4,
                'costPerUnit': this.state.selectedProduct.costPerUnit_5
            }

            if (!isNewProduct) {
                request['uuid'] = this.state.selectedProduct.uuid_0;
            }

            this.props.api.post(
                `/product-template/${isNewProduct ? 'create' : 'edit'}/${!isNewProduct ? request['uuid']: ''}`,
                request,
                (data) => {
                    this.setState({
                        downloading: false,
                        saving: false,
                        selectedProduct: null,
                        modal: false
                    },
                    () => {
                        this.props.api.dispatchEvent('RELOAD_TABLE');
                        this.props.showToast({
                            title: this.props.t('common.toast.dataSaved'),
                            color: "success"
                        });
                    })
                },
                (errorObject) => {
                    this.setState(
                        {
                            downloading: false,
                            saving: false,
                            selectedProduct: null
                        },
                        () => {
                            this.props.showToast({
                                errorObject: errorObject,
                                title: this.props.t('common.toast.error'),
                                color: 'danger'
                            });
                        }
                    );
                },
            )
        })
    }

    onInvalid(e) {
        if (e) {
            e.preventDefault();
        }
        let errorObject = {
            "ident": "",
            "data": {
                "error": {
                    "ident": ""
                }
            }
        };
        if (
            e.target
            && e.target?.previousElementSibling
            && e.target.previousElementSibling.tagName === 'LABEL'
        ) {
            errorObject.ident = this.props.t(`common.tooltip.invalid`);
            errorObject.data.error.ident = e.target.previousElementSibling.innerText;
        }
        this.props.showToast({
            title: this.props.t('common.toast.checkInputData'),
            color: 'danger',
            errorObject: errorObject.ident ? errorObject : null
        });
    }

    editItem(value, field) {
        const {
            selectedProduct
        } = this.state;
        let newSelectedProduct = { ...selectedProduct };

        this.setState({ selectedProduct: { ...newSelectedProduct, [field]: value } })
    }

    getSyncPreview() {
        this.setState({
            modalSyncLoading: true,
            syncPreview: []
        }, () => {
            this.props.api.get(
                `/product-template/sync-business-central-preview`,
                (data) => {
                    this.setState({
                        syncPreview: data,
                        modalSyncLoading: false
                    })
                },
                (errorObject) => {
                    this.setState({ modalSyncLoading: false });
                    errorObject.data.error.details[0].forEach(errorItem => {
                        this.props.showToast({
                            errorObject: errorObject,
                            title: this.props.t('common.toast.error') + `: ${errorItem.bcId} (${errorItem.label})`,
                            color: 'danger'
                        });
                    })
                }
            );
        });
    }

    showSyncModal() {
        this.setState({ modalSync: true }, () => this.getSyncPreview());
    }

    syncMaybeExecute(andClose) {
        const callback = () => {
            this.setState({
                modalSync: true,
                modalSyncLoading: true,
                syncPreview: []
            }, () => {
                this.props.api.post(
                    `/product-template/sync-business-central-execute`,
                    {
                        "product": {
                            "uuids": this.state.syncItems
                        },
                    },
                    (data) => {
                        this.setState({
                            syncPreview: data,
                            modalSyncLoading: false
                        }, () => {
                            if (andClose) {
                                this.setState({
                                    modalSync: false,
                                }, () => {
                                    this.props.showToast({
                                        title: this.props.t('common.toast.dataSaved'),
                                        color: "info",
                                        duration: 2500,
                                    });
                                });
                            }
                        })
                    },
                    (errorObject) => {
                        this.setState({ modalSyncLoading: false });
                        this.props.showToast({
                            errorObject: errorObject,
                            title: this.props.t('common.toast.error'),
                            color: "danger"
                        });
                    }
                );
            });
        }

        this.confirm({
            title: this.props.t('page.products.basicData.sync.confirm.title'),
            text: this.props.t('page.products.basicData.sync.confirm.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('page.products.basicData.sync.confirm.confirm'),
            confirmButtonColor: '#fdbf21',
            cancelButtonColor: '#aaa',
            cancelButtonText: this.props.t('page.products.basicData.sync.confirm.cancel')
        }, callback);
    }

    toggleSync(itmuuid) {
        debugger;
        const wasSyncAll = this.state.syncAll;
        const oldItems = [...this.state.syncItems];
        const allItems = [...this.state.syncPreview].filter(itm => itm.sync).filter(itm => !itm.error).map(itm => itm.uuid);
        let bcId = "";

        if (itmuuid === 'all') {
            this.setState({ syncAll: !wasSyncAll, syncItems: wasSyncAll ? [] : allItems })
        } else if (allItems.includes(itmuuid)) {

            let validItems = [];
            this.state.syncPreview.filter(itm => !itm.error).forEach(itm => {
                if (itm.uuid === itmuuid) {
                    bcId = itm.bcId;
                }
                if (bcId && bcId === itm.bcId) {
                    validItems.push(itm.uuid);
                }
            });

            if (oldItems.includes(itmuuid)) {
                const syncItems = oldItems.filter(itm => !validItems.includes(itm));
                this.setState({ syncAll: false, syncItems });
            } else {
                oldItems.push(...validItems);
                this.setState({ syncAll: false, syncItems: oldItems });
            }
        }
    }


    render() {

        const {
            loading,
            selectedProduct,
            syncAll,
            syncItems
        } = this.state;

        const productForm = (
            <Form method='post'
                  onSubmit={(e) => { this.maybeSave(e) }}
                  onInvalid={(e) => { this.onInvalid(e) }}>
                <div>
                    <FormGroup>
                        <Form.Label>
                            {this.props.t('page.products.basicData.name')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={this.props.t('page.products.basicData.placeholder.name')}
                            value={selectedProduct?.label_1 || ""}
                            onChange={(e) => {
                                this.setState({ selectedProduct: { ...selectedProduct, ['label_1']: this.val(e) } })
                            }}
                        />
                    </FormGroup>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Form.Label>
                                    {this.props.t('page.products.basicData.bcId')}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={this.props.t('page.products.basicData.placeholder.bcId')}
                                    value={selectedProduct?.bcId_2 || ""}
                                    onChange={(e) => {
                                        this.setState({ selectedProduct: { ...selectedProduct, ['bcId_2']: this.val(e) } })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Form.Label>
                                    {this.props.t('page.products.basicData.type')}
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedProduct?.type_3 || ""}
                                    onChange={(e) => {
                                        this.setState({ selectedProduct: { ...selectedProduct, ['type_3']: this.val(e) } })
                                    }} >
                                    <option value="">{this.props.t('page.products.basicData.productType.empty')}</option>
                                    {
                                        (this.state.productTypes || []).map(productType => {
                                            return (
                                                <option key={productType} value={productType}>{productType}</option>
                                            )
                                        })
                                    }
                                </Form.Control>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Form.Label>
                                    {this.props.t('page.products.basicData.unit')}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={this.props.t('page.products.basicData.placeholder.unit')}
                                    value={selectedProduct?.unit_4 || ""}
                                    onChange={(e) => {
                                        this.setState({ selectedProduct: { ...selectedProduct, ['unit_4']: this.val(e) } })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Form.Label>
                                    {this.props.t('page.products.basicData.costPerUnit')}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={this.props.t('page.products.basicData.placeholder.costPerUnit')}
                                    value={selectedProduct?.costPerUnit_5 || ""}
                                    onChange={(e) => {
                                        this.setState({ selectedProduct: { ...selectedProduct, ['costPerUnit_5']: this.val(e) } })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="border-top mt-20 pt-20">
                    <button
                        type="submit"
                        className="btn btn-uniform btn-success"
                        disabled={loading}
                    >
                        <Icon name="save" />
                        <span className="pl-10">
                            {this.props.t('page.products.saveData')}
                        </span>
                    </button>
                </div>
            </Form>
        )

        return (
            <Fragment>

                <Modal
                    show={this.state.modalSync}
                    onHide={() => {
                        this.setState({modalSync: false, syncAll: false, syncItems: [] }) }}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.products.basicData.sync.title')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped bordered hover responsive size="sm" className="sticky-thead">
                            <thead>
                            <tr>
                                <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 90 }}>ID</th>
                                <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff' }}>{this.props.t('page.products.basicData.sync.product')}</th>
                                <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 120 }}>{this.props.t('page.products.basicData.sync.oldPrice')}</th>
                                <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 120 }}>{this.props.t('page.products.basicData.sync.newPrice')}</th>
                                <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 100 }}>{this.props.t('page.products.basicData.sync.baseUnitOfMeasure')}</th>
                                <th className='px-5 py-2' style={{ zIndex: 2, background: '#fff', minWidth: 100 }}>{this.props.t('page.products.basicData.sync.difference')}</th>
                                <th className='px-5 py-2 text-center' style={{ zIndex: 2, background: '#fff' }}>
                                    <Form.Check
                                        id={`sync-all`}
                                        type="checkbox"
                                        custom={true}
                                        checked={syncAll}
                                        onChange={(e) => { this.toggleSync('all') }}
                                        label='SYNC'
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.syncPreview.map(itm => (
                                <tr key={`sync-preview-${itm.uuid}`}
                                    style={{
                                        background: Math.abs(this.parseFloatFixed(itm.costPerUnitDifference)) > 0 && !itm.error ? "#dfeeee" : 'transparent'
                                    }}>
                                    <td className='px-5 py-2'>{itm.sync ? `✔ ` : `✗ `}{itm.bcId}</td>
                                    <td className='px-5 py-2'>{itm.label}</td>
                                    <td className='px-5 py-2'>{itm.costPerUnit}</td>
                                    {
                                        itm.error ? (
                                            <td className='px-5 py-2' colSpan="4">{itm.error}</td>
                                        ) : (
                                            <>
                                                <td className='px-5 py-2'>{itm.newCostPerUnit}</td>
                                                <td className='px-5 py-2'>{itm.baseUnitOfMeasure}</td>
                                                <td className='px-5 py-2 strong'>{itm.costPerUnitDifference}</td>
                                                <td className='px-5 py-2 text-center'>
                                                    {
                                                        itm.sync ? (
                                                            <Form.Check
                                                                id={`sync-itm-${itm.uuid}`}
                                                                type="checkbox"
                                                                checked={syncItems.includes(itm.uuid)}
                                                                onChange={(e) => { this.toggleSync(itm.uuid) }}
                                                            />
                                                        ) : null
                                                    }
                                                </td>
                                            </>
                                        )
                                    }
                                </tr>
                            ))}
                            {this.state.modalSyncLoading && (
                                <tr>
                                    <td colSpan={7} className="text-center">
                                        <Spinner animation="border" />
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="p-20 border-top bg-white" style={{ position: 'sticky', bottom: 0 }}>
                        <Button
                            variant="info"
                            disabled={this.state.modalSyncLoading}
                            className="mr-10"
                            onClick={() => {
                                this.getSyncPreview()
                            }}>
                            {this.props.t('common.operation.refresh')}
                        </Button>
                        <Button
                            variant="warning"
                            disabled={this.state.modalSyncLoading || !syncItems.length}
                            onClick={() => {
                                this.syncMaybeExecute(true)
                            }}>
                            {this.props.t('common.operation.sync')}
                            {` (${syncItems.length})`}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modal}
                    onHide={() => {
                        this.setState({modal: false, selectedProduct: null})
                    }}
                    backdrop="static"
                    keyboard={false}
                    size="sm"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('page.products.basicData.edit')}
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>
                        {productForm}
                    </Modal.Body>
                </Modal>


                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Header className="px-10 py-10 background-white">
                                <div className="d-flex align-items-center justify-content-between">
                                    <button
                                        type="button"
                                        className="btn btn-uniform text-success"
                                        disabled={false}
                                        onClick={(e) => {
                                            this.setState({
                                                modal: true,
                                                selectedProduct: {
                                                    'uuid_0': null,
                                                    'label_1': '',
                                                    'bcId_2': '',
                                                    'type_3': '',
                                                    'unit_4': '',
                                                    'costPerUnit_5': ''
                                                }
                                            })
                                        }}
                                    >
                                        <Icon name="plus-square"/>
                                        <span className="pl-10">
                                        {this.props.t(`page.products.defaultLabelProduct`)}
                                    </span>
                                    </button>

                                    <div className="input-group-prepend" style={{marginLeft: "auto"}}>
                                        <button
                                            type="button"
                                            disabled={loading}
                                            className="btn btn-uniform text-warning mr-10"
                                            onClick={(e) => {
                                                this.showSyncModal()
                                            }}
                                        >
                                            <Icon name="refresh-cw"/>
                                            <span className="pl-10">
                                                    {this.props.t('common.operation.sync')}
                                                </span>
                                        </button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className="p-0">
                            <Datatable
                                    id={'products-table'}
                                    endpoint={`product-template/list`}
                                    withData={{}}
                                    columns={[
                                        {
                                            Header: this.props.t('page.knowledge.table.name'),
                                            accessor: "label_1",
                                            sortable: true,
                                            filterable: true
                                        },
                                        {
                                            Header: (<>&nbsp;</>),
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                            width: 90
                                        }
                                    ]}
                                    sort={[
                                        {
                                            id: "label_1",
                                            desc: false
                                        }
                                    ]}

                                    withCheckbox={false}
                                    onChecked={(data) => {
                                    }}
                                    transform={[
                                        {
                                            'field': 'label_1',
                                            'fn': (row) => {
                                                return (
                                                    <button
                                                        type="button"
                                                        className="btn btn-link"
                                                        data-tip={this.props.t('common.tooltip.edit')}
                                                        style={{textTransform: 'none'}}
                                                        onClick={(e) => {
                                                            this.setState({modal: true, selectedProduct: row})
                                                        }}
                                                    >
                                                        <span>{row.label_1}</span>
                                                    </button>
                                                )
                                            }
                                        }
                                    ]}
                                    actions={[
                                        {
                                            action: "edit",
                                            callback: (productData) => {
                                                this.setState({modal: true, selectedProduct: productData});
                                            }
                                        },
                                        {
                                            action: 'delete',
                                            callback: (productData) => {
                                                this.confirmRemove(() => {
                                                    this.props.api.post(
                                                        `/product-template/delete/${productData.uuid_0}`,
                                                        {},
                                                        () => {
                                                            this.props.api.dispatchEvent('RELOAD_TABLE');
                                                        },
                                                    )
                                                })
                                            }
                                        }
                                    ]}
                                    {...this.props}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default Content
