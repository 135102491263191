export const consentsPoland = [
    'consentAgreement',
    'consentSalesLeaderContest',
    'consentAffIsEmployed',
    'consentAffIsContractor',
    'consentAffIsStudent',
    'consentAffIsUnemployed',
    'consentAffIsRetired'
];

export const preferredCooperationModels = [
    'ambassador',
    'partner',
    'wholesaler',
    'contractor'
];

export const companyTypes = [
    'PARTNER',
    'SUBCONTRACTOR'
]

export const preferredCooperationTypes = [
    'architect',
    'designer',
    'constructionManager',
    'generalContractor',
    'contractor',
    'developer',
    'roofingMaterialDistributor',
    'distributorOfBuildingMaterialsAndRoofing',
    'distributorOfProducts',
    'bankingCreditAndConsultingActivities',
    'employeeLocalGovernmentInstitution',
    'roofer',
    'carpenter',
    'electrician',
    'bricklayer',
    'other',
];

export const assetTypes = [
    'itHardware_laptop',
    'itHardware_pc',
    'itHardware_monitor',
    'itHardware_mobilePhone',
    'itHardware_simCard',
    'itHardware_tablet',
    'itHardware_keyboard',
    'itHardware_mouse',
    'itHardware_printer',
    'itSoftware_software',
    'product_roofConstruction',
    'product_other',
    'service_marketing',
    'service_hr',
    'service_it',
    'service_other',
];

export const articleCategories = [
    'sales',
    'partners',
    'operations',
];

export const assetStatuses = [
    'initialized',
    'rejected',
    'requestedAgain',
    'rejectedFinal',
    'accepted',
    'orderCreated',
    'orderMade',
    'orderPaid',
    'serviceReceived',
    'assetReceived',
];

export const offerAcceptingConstrutorPoland = [
    'piotr.rojewski@sunroof.se',
    'piotr.desaniuk@sunroof.se'
];

export const offerAcceptingConstrutorNamePoland = [
    'Piotr Rojewski',
    'Piotr Desaniuk'
];

export const offerSigningConstrutorPoland = [
    'piotr.rojewski@sunroof.se',
    'marcin.ploski@sunroof.se'
];

export const offerSigningConstrutorNamePoland = [
    'Piotr Rojewski',
    'Marcin Płoski'
];

export const offerSigningConstrutorSweden = [
    'bjorn.nordkvist@sunroof.se',
];

export const agreementTypes = [
    'ata',
    'alp',
    'contract',
    'annex',
    'acceptance',
    'termination',
    'documentation',
];

export const signingParties = [
    'client',
    'coOwner',
    'client_coOwner',
];

export const reminderTimeOptionsInDays = [
    4,
    7
];

export const contractTypes = [
    'purchase',
    'leasing',
    'subscription',
    'rental',
];

export const headquarters = [
    'SunRoof International Holding AB',
    'SunRoof Technology Sp. z o.o.',
    'SunRoof Germany GmbH',
    'SunRoof Sverige AB',
    'SR International Services Sp. z o. o.',
    'Redlogger AB',
    'Sunroof USA, Inc.'
];

export const grants = [
    'LIFT - Accelerator program',
    'InnoEnergy - Ramp-Up',
    'PARP - Go to Brand',
];

export const orientation = {
    'n': 0,
    'e': 90,
    's': 180,
    'w': 270, // -90
    'ne': 45,
    'nw': 315,
    'se': 135,
    'sw': 225
};

export const mapCenter = {
    'POLAND': { lat: 52.237049, lng: 21.017532 },
    'SWEDEN': { lat: 59.3293, lng: 18.0686 },
    'GERMANY': { lat: 52.52, lng: 13.4050 },
    'USA': { lat: 39.8283, lng: -98.5795 },
};

export const warehouseLocation = {
    'SVARVARGATAN_6_VANERSBORG': { lat: 58.36109028698508, lng: 12.333298116160432 }
};

export const teamsCategories = [
    'roofers',
    'electricians',
    'projectManagers',
    'siteManagers',
    'electricDesigners',
    'bipvDesigners',
    'salesReps'
];

export const publicPaths = {
    'assets': process.env.PUBLIC_URL + '/assets/',
    'markers': process.env.PUBLIC_URL + '/assets/marker/'
};

export const acceptFileType = [
    'application/acad',
    'image/vnd.dwg',
    '.dwg',
    '.pln',
    '.pvprj',
    '.mc3',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
    'application/pdf',
    'image/*',
    'audio/*',
    'video/*',
    '.zip',
];

export const today = new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(Date.now());

export const appEnvCurrent = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();

export const appVersionCurrent = appEnvCurrent + '.' + process.env.REACT_APP_VERSION + '.' + process.env.REACT_APP_BUILD_STAMP;

export const appEnv = {
    local: 'local',
    dev: 'dev',
    test: 'test',
    uat: 'uat',
    prod: 'prod'
}

export const domain = {
    'msCrm': [appEnv.local, appEnv.dev, appEnv.test, appEnv.uat].includes(appEnvCurrent)
        ? `sunroofdev.crm4.dynamics.com`
        : `sunroof.crm4.dynamics.com`
};

export const mapperMarker = {
    'projectDone': publicPaths.markers + 'marker_green.png',
    'bipvDone': publicPaths.markers + 'marker_purple.png',
    'bipvOngoing': publicPaths.markers + 'marker_orange.png',
    'pending': publicPaths.markers + 'marker_yellow.png',
    'cancelled': publicPaths.markers + 'marker_red.png',
};

export const kanbanTerminal = [
    "done",
    "gotowe",
    "ready",
    "bereit",
    "redo",
    "projektet avslutat",
    "avslutat",
    "projektavslut",
    "avslut",
    "20. projekt avslutat", // keep lcase!
];

export const projectTypeRegional = {
    "POLAND": {
        "roof": "Dach",
        "carport": "Carport",
        "facade": "Fasada",
        "custom": "Wycena niestandardowa"
    },
    "SWEDEN": {
        "roof": "Tak",
        "carport": "Carport",
        "facade": "Fasad",
        "custom": "Anpassad prissättning"
    },
    "GERMANY": {
        "roof": "Dach",
        "carport": "Carport",
        "facade": "Fassade",
        "custom": "Benutzerdefinierte Preise"
    },
    "USA": {
        "roof": "Roof",
        "carport": "Carport",
        "facade": "Facade",
        "custom": "Custom pricing"
    }
};

export const ignoreErrors = [
    're.jrc.ec.europa.eu',
    'resulted in a `404 Not Found`',
    'error_user_already_registered',
    'Validator:',
    'Podaj poprawną wartość',
    'clipboard:',
    'einen gültigen Wert ein',
];

export const chmodEnum = {
    'READONLY': '0444',
    'FOR_CLIENT': '1000',
    'FOR_CLIENT_AND_READONLY': '1444'
}

export const s3Bucket = {
    "BUCKET_SUNROOF_BP": 'sunroof-bp',
    "BUCKET_SUNDESK_TEST_PUBLIC": 'sundesk-test-public',
}

export const projectParticipants = [
    'client',
    'projectpm', 'projectsm', 'projectbipvdesigner', 'projectcostcontroller',
    'projectelectricaldesigner', 'projectconstructionengineer', 'projectpmomanager',
    'projectlogisticsmanager', 'projectdesignmanager',
];

export default {
    consentsPoland,
    preferredCooperationModels,
    preferredCooperationTypes,
    assetTypes,
    assetStatuses,
    offerAcceptingConstrutorPoland,
    offerSigningConstrutorPoland,
    agreementTypes,
    contractTypes,
    headquarters,
    grants,
    signingParties,
    mapCenter,
    warehouseLocation,
    teamsCategories,
    publicPaths,
    acceptFileType,
    appEnv,
    appEnvCurrent,
    appVersionCurrent,
    domain,
    mapperMarker,
    today,
    kanbanTerminal,
    projectTypeRegional,
    ignoreErrors,
    chmodEnum,
    s3Bucket,
    projectParticipants,
}

